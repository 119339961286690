import { LinkColorIcon } from "../atoms/Link";
import Text from "components/atoms/Text";
import {
  useLocationVendedor,
  useLocationCliente,
} from "../../lib/useLocationLanding";
import PropTypes from "prop-types";

export default function Barra({
  textBarra,
  url,
  textLink,
  colorLink,
  classname,
}) {
  const vendedor = useLocationVendedor();
  const clientes = useLocationCliente();

  return (
    <div
      className={
        "landings relative z-50 flex justify-center align-center items-center flex-col w-full gap-y-px py-10 bg-white min-h-[12rem] lg:mt-8 " +
        classname
      }
    >
      {textBarra ? (
        <div className="px-[5%] lg:px-[10%] text-center">
          <Text textColor="#000000" text={textBarra} />
        </div>
      ) : (
        ""
      )}
      {url ? (
        <div className="px-[5%] lg:px-[10%]">
          {vendedor || clientes ? (
            <LinkColorIcon
              url={url}
              textLink={textLink}
              colorLink={colorLink}
              tab="_self"
            />
          ) : (
            <LinkColorIcon
              url={url}
              textLink={textLink}
              colorLink={colorLink}
            />
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

Barra.propTypes = {
  text: PropTypes.string,
  textLink: PropTypes.string,
  url: PropTypes.string,
  colorLink: PropTypes.string,
};
