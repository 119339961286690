import { useState, useEffect } from 'react'

export function useLocationLanding() {
  const [comision, setIsComision] = useState(false);

  useEffect(() => {
    if (window.location.pathname === "/comision-0-vendedores-asesores") {
      setIsComision(true);
    } else {
      setIsComision(false);
    }
  }, []);

  return comision;
}

export function useLocationVendedor() {
  const [proVend, setIsProVend] = useState(false);

  useEffect(() => {
    if (window.location.pathname === "/propuesta-vendedor") {
      setIsProVend(true);
    } else {
      setIsProVend(false);
    }
  }, []);

  return proVend;
}

export function useLocationCliente() {
  const [proCliente, setIsCliente] = useState(false);

  useEffect(() => {
    if (window.location.pathname === "/propuesta-cliente") {
      setIsCliente(true);
    } else {
      setIsCliente(false);
    }
  }, []);

  return proCliente;
}

export function useLocationPresentarCliente() {
  const [preCliente, setIsPreCliente] = useState(false);

  useEffect(() => {
    if (window.location.pathname === "/tenemos-un-cliente-para-ti") {
      setIsPreCliente(true);
    } else {
      setIsPreCliente(false);
    }
  }, []);

  return preCliente;
}
